<template>
  <div id="app">
    <div v-if="step==1">

      <!-- 第一步 -->
      <div class="bg_green">
        <div class="white_box3">
          <!-- 标题 -->
          <div class="orange_title" style="font-weight: normal;">请选择用户</div>
          <div class="white_box3_in pdb_0">
            <!-- 用户 -->
            <div class="user pd_3p6_0 clear" @click="showPopup" v-if="customerUser.businessCustomerUser">
              <div class="user_avatar" :style="{backgroundImage:`url(${img})`}"></div>
              <div class="user_msg">
                <span>{{ customerUser.businessCustomerUser.name }}</span>
                <label>{{
                    customerUser.businessCustomerUser.business.name
                  }}，{{ customerUser.businessCustomerUser.structure.name }}</label>
              </div>
              <div class="user_r2 ico iconfont">&#xe602;</div>
            </div>
          </div>
        </div>
      </div>

      <!-- 选择用户 -->
      <van-popup v-model="show">
        <div class="white_box3_in pdb_0">
          <div class="sub_title2">选择用户</div>
          <!-- 用户 -->
          <div v-for="(option,index) in customerUserOptions" :key="index"
               class="user under_line pd_3p6_0 clear">
            <div class="user_avatar" :style="{backgroundImage:`url(${img})`}"></div>
            <div class="user_msg">
              <span>{{ option.businessCustomerUser.name }}</span>
              <label>{{ option.businessCustomerUser.business.name }}，{{
                  option.businessCustomerUser.structure.name
                }}</label>
            </div>
            <div class="user_r2 ico iconfont">
              <button class="button_green short" @click="switchUser(option)">选择</button>
            </div>
          </div>
        </div>
      </van-popup>

      <!-- 第二步 -->
      <div class="white_box3 mgt_3vw">
        <!-- 标题 -->
        <div class="dark_title" style="font-weight: normal;">{{ rechargeTitle }}
        </div>
        <div class="white_box3_in" style="padding-bottom:0">
          <!-- 列表 -->
          <van-checkbox-group v-model="productBundleIds" ref="checkboxGroup" @change="change"
                              v-if="productbundleBuy && productbundleBuy.setValue ==='1'"
          >
            <div v-for="(option,index) in noneBundleOption" :key="index"
                 class="user under_line pd_3p6_0 clear">
              <div class="user_msg">
                <span>{{ option.name }}</span>
                <label>{{ option.cycleStart | date('yyyy-MM-dd') }} — {{
                    option.cycleEnd |
                        date('yyyy-MM-dd')
                  }}</label>
              </div>
              <div class="user_r right_0">
                {{ option.totalAmount.toFixed(2) }}
                <van-checkbox :name="option.id" :value="option.id" shape="square"
                              checked-color="#34AC40" icon-size="12px"></van-checkbox>
              </div>
            </div>
          </van-checkbox-group>

          <div class="txb" v-if="freedomBuy && freedomBuy.setValue ==='1'">
            <van-field class="padd_8px_0" style="padding:3.6vw 0; !important;" @blur="change"
                       v-model="freeAmount" type="number"
                       input-align="right"
                       label="零钱充值"
                       @keyup.enter="recharge"
                       placeholder="请输入要充值的金额"/>
          </div>

          <!-- 统计 -->
          <div class="total_count">需充值：{{ showAmount.toFixed(2) }}元</div>
        </div>
      </div>

      <!-- 第三步 -->
      <div class="white_box3 mgt_3vw">
        <!-- 标题 -->
        <div class="dark_title" style="font-weight: normal;">
          <span>请确认充值金额 </span>
        </div>
        <!-- 金额+选择帐号 -->
        <div v-if="showFreedomDeductible">
          <van-cell
              v-for="(item, index) in list"
              :border="false"
              clickable
              :key="item.key"
              :title="` ${item.value}`"
              @click="toggle(index)"
          >
            <template #right-icon>
              <van-checkbox :name="item.key" v-model="tChecked"/>
            </template>
          </van-cell>
        </div>

        <div class="amount" style="text-align: right">合计: ￥ {{ showAmount.toFixed(2) }}</div>
        <div v-if="paymentInfo && paymentInfo.needPassword === '1'">
          <div class="txb border_none">
            <div style="display: flex; margin: 12px 16px 0 16px;">
              <div style="flex: 1;">
                {{ '付款帐号' }}
              </div>
              <div>
                <div>{{ bindingCardNo }}</div>
                <div style="font-size: 13px; float: right; margin-top: 4px; color: #999999;">{{
                    bindingCardBankName
                  }}
                </div>
              </div>
            </div>
          </div>
          <div class="agreen clear">
            <van-checkbox
                v-model="checked"
                shape="square"
                checked-color="#E9331E"
                icon-size="14px"/>
            我已阅读并同意
            <font @click="showNotice2 = true">《广发银行委托代扣协议书》</font>
          </div>
        </div>

        <!-- 按钮 -->
        <div class="two_btn" style="margin-top: 12px;">
          <ul class="clear">
            <li>
              <button class="button_dark" @click="backUrl?goBack():$router.back(-1)">
                取消
              </button>
            </li>
            <li>
              <button class="button_green" @click="recharge">提交</button>
            </li>
          </ul>
        </div>

<!--        <div class="banner2" v-if="showGFBanner()" style="margin-top: 3vw;">-->
<!--          <van-swipe class="swipe" :autoplay="3000" indicator-color="white">-->
<!--            <van-swipe-item class="clear"><img src="../assets/images/guangfa3500/banner_guangfa3500.jpg" @click="onClickBanner">-->
<!--            </van-swipe-item>-->
<!--          </van-swipe>-->
<!--        </div>-->
      </div>

      <!-- 弹出协议2 -->
      <div class="bg_dark" v-show="showNotice2">
        <div class="bank_notice">
          <div class="bank_notice_t">
            广发银行委托代扣协议书
            <span class="iconfont" @click="showNotice2 = false">&#xe740;</span>
          </div>
          <div class="bank_notice_b">
            广发银行委托代扣协议书（以下简称“本协议”）是广发银行股份有限公司（以下简称“广发银行”）与个人用户（以下简称“您”）就广发银行代扣业务的相关事宜达成的协议。您通过点击“同意”或以其他方式选择接受本协议，即表示您知悉并同意接受本协议的全部内容同意按照本协议约定履行义务。在确认接受本协议之前，请您仔细阅读本协议的全部内容。如果您不同意本协议的任何内容，或者无法准确理解相关条款的解释，请您立即关闭相关办理业务，务必不要进行后续任何操作。<br>
            第一条 定义<br>
            广发银行委托代扣服务，是指广发银行根据您的授权，执行收款人向您在广发银行开立账户发起的扣款指令，无须经交易验证直接完成将您账户资金扣划至收款人账户的服务（以下简称“代扣服务”）。<br>
            第二条 个人用户的权利义务<br>
            （一）您申请开通代扣服务时，签约本人名下的广发银行账户，您保证提供给广发银行的身份信息、身份资料的真实性、准确性、完整性、合法性和有效性。您应当对自己的账户信息进行严格保密和控制，通过相关账户信息进行签约操作的行为均视为您本人的行为。<br>
            （二）您应妥善保管本人包括但不限于身份信息、银行账号、银行账号密码以及手机动态验证码等安全认证工具，不得将以上任何信息向他人透露，否则由此导致的损失与后果将由您本人自行承担，广发银行不因此承担任何责任。<br>
            （三）您保证业务开通时在广发银行预留的手机号为本人手机号码且与在收款人注册时预留的手机号一致，因您未及时更新本人在广发银行预留的手机号码等信息导致本服务无法提供或提供时发生错误，由此产生的后果和损失将由您自行承担。<br>
            （四）您承诺不会利用本服务从事任何非法的或侵犯任何其他第三方权益的行为，否则广发银行有权立即单方面终止为您提供本协议项下代扣服务，并依法采取相应的法律措施；如果因您的行为给广发银行造成损失的，您应承担全部责任。<br>
            （五）您保证账户余额充足、账户状态正常，确保广发银行能够按照扣款信息及时付款。<br>
            （六）您授权广发银行根据收款人的支付指令从您的账户中扣划指定款项至收款人指定的银行账户。授权具体事项以您与广发银行签订的代扣信息为准。<br>
            （七）如您的任一授权事项发生变更时，请及时通过广发银行柜面、手机银行等渠道变更，因您未及时更新授权信息，导致本服务无法提供或提供时发生错误，由此产生的后果将由您自行承担。<br>
            （八）您保证遵循反洗钱法律法规及监管规定，切实履行反洗钱、反恐怖融资和扩散融资、反逃税义务，资金来源和用途不涉及违规、违法活动。您如因涉嫌洗钱、恐怖融资和扩散融资、逃税、制裁等违法犯罪活动或违反相关法律规定引起纠纷的，自愿承担相应的法律责任。<br>
            第三条 广发银行的权利义务<br>
            （一）广发银行有义务在交易过程中对授权事项进行逐笔验证，如未与您建立代扣授权关系或者授权事项不符的，广发银行有权拒绝办理相关业务。<br>
            （二）广发银行有权对您进行客户尽职调查，您应积极配合并提供真实、准确、完整、合法、有效的相关信息和资料；如您不配合，您同意广发银行有权中止交易，直至终止业务关系。<br>
            （三）广发银行通过微信银行、手机银行、短信等其中之一方式通知您代扣相关信息。<br>
            （四）广发银行有义务在技术上确保代扣服务的安全、有效、正常运行，保证您的正常使用。<br>
            （五）广发银行可以在法律法规许可或您授权的范围内使用您在广发银行留存的资料或交易信息等。<br>
            （六）如广发银行原因造成错扣、漏扣、多扣的，经核实后，广发银行应对其造成的失误承担及时纠正责任。<br>
            （七）广发银行在终止提供本服务后，若发现您之前存在违法或违反本协议目的的使用行为给广发银行造成损失的，则广发银行仍可据此要求您承担相应赔偿责任并保留通过司法途径予以解决的权利。<br>
            第四条 授权终止<br>
            （一）您可通过广发银行柜面、手机银行等渠道发起终止已授权的代扣服务。如您通过广发银行发起终止授权，您应及时通知收款人，如后期收款人未能按照约定完成代扣交易，因此产生的资金纠纷及相关责任由您自行承担。<br>
            （二）您通过广发银行渠道终止代扣服务的，本协议终止，您后续仍需使用广发银行代扣服务，可重新发起签约开通代扣服务。<br>
            （三）如您怀疑签约的账户信息发生泄露，可通过广发银行柜面、手机银行等渠道发起终止已授权的代扣服务，或者立即通知广发银行并按照有关挂失、销户的规定办理账户挂失、销户。<br>
            第五条 异议处理<br>
            （一）在本协议下广发银行是支付指令的执行方，涉及收款人以及交易的真实性准确性，以及扣款环节中遇到的任何问题（因广发银行原因导致的除外），由您与收款人协调解决，广发银行可在法律法规及监管规定允许的前提下提供必要的协助。<br>
            （二）您知晓并同意，广发银行仅根据本协议约定向您提供代扣服务，对于您与收款人之间发生的纠纷，由您与收款人协商处理。如您对扣款用途、扣款金额等有异议由您与收款人协商处理。<br>
            （三）如因收款人向广发银行传送授权事项有误，导致广发银行不能按约定扣划资金或致使您及收款人发生损失的，广发银行不承担责任，由您与收款人协商解决。<br>
            （四）因通讯等原因导致您未能及时接收广发银行发出的代扣信息，您可以通过广发银行的手机银行、营业网点查询。<br>
            第六条 不可抗力<br>
            由于不能预见、不能克服、不能避免等不可抗力或不能控制等客观因素导致通讯或系统中断，以致影响代扣业务不能正常完成的，您认可并同意广发银行无需承担任何法律责任。<br>
            第七条 特别约定条款<br>
            （一）广发银行对本协议有权进行必要的修改，修改的条款对办理本项业务的所有代扣用户均有约束力。广发银行将通过官网、短信等其中之一方式公示，公示时间30日，如您不同意修改条款，有权在公示期内注销本协议代扣功能，公示期满，您未提出注销代扣功能的，视同您认可修改后的条款对您的约束力。<br>
            （二）为了校验信息的准确性，对您的身份及其他必要信息进行识别、验证，您同意授权广发银行向相关第三方提供您的信息，包括姓名、账号、证件号码、联系方式、用途、限额、协议号等。对于上述信息，广发银行会根据相关法律法规及银行相关信息保护制度规定进行共享，并要求合作方履行信息保密义务。您知晓本协议中信息被提供和使用的风险，这些风险包括但不限于：该等信息被广发银行依法提供给第三方后被他人不当利用的风险，由于除广发银行以外的原因造成的故障以及其他不可抗力因素导致的隐私泄密的风险，或因您的业务状况较好而造成被接收信息的第三方营销产品或服务等打扰的风险。<br>
            （三）本协议适用中华人民共和国法律。为本协议之目的，中华人民共和国法律不包括香港特别行政区、澳门特别行政区及台湾地区法律。<br>
            （四）因履行本协议而产生的或与本协议有关的任何争议，应协商解决；协商不成的，可以向有管辖权人民法院提起诉讼。<br>
            （五）自您同意接受本协议之日起生效。您在协议签约中提供的授权事项为本协议的一部分，与本协议具有同等法律效力。<br>
            用户确认:您确认已仔细阅读、理解并接受了本协议的全部条款和内容，对本协议条款的含义及相应的法律后果已全部知晓并充分理解，愿意遵守本协议全部内容；本人确认，广发银行已就增加本人责任或限制本人权利、免除或限制广发银行责任或广发银行单方拥有某些权利的所有条款向本人作出相应提示和说明，本人对所有条款完全理解并自愿接受该内容。
          </div>
        </div>
      </div>
    </div>

    <div class="loading_all" v-if="loading">
      <van-loading type="spinner" color="#ffffff" vertical>{{ loadingContent }}</van-loading>
    </div>

    <div v-if="step==2">
      <div>
        <div class="bank3_title">身份验证</div>
        <div class="bank3_sub_title">请验证支付密码确认本人操作</div>
        <div class="yzm2">
          <van-password-input
              :value="password"
              :gutter="10"
              :focused="showPasswordKeyboard"
              @focus="showPasswordKeyboard = true"
          />
        </div>
        <div style="text-align: center; padding-top: 20px;">
          <div
              style="position: absolute; padding-top: 28px; color: rgb(28, 122, 235); margin-right: 24px; right: 0; font-size: 12px;"
              @click="$router.push({path: '/verification'})">
            忘记支付密码?
          </div>
          <a-button type="link" @click="step=1" class='cancel_recharge'>
            取消充值
          </a-button>
        </div>
        <van-number-keyboard
            v-model="password"
            :maxlength="6"
            :show="showPasswordKeyboard"
            @blur="showPasswordKeyboard = false"
        />
      </div>
    </div>
  </div>

</template>

<style scoped>
::v-deep .van-checkbox {
  margin-left: 10px;
}

::v-deep .agreen .van-checkbox {
  margin-left: 0;
}

.agreen {
  padding-bottom: 0;
}

.van-popup--center {
  width: 90vw;
}

::v-deep .van-cell.padd_8px_0 {
  padding: 8px 0 !important;
}

::v-deep .van-field__label {
  height: 34px;
  line-height: 34px;
}

::v-deep .van-field__value {
  height: 34px;
  line-height: 34px;
}
</style>

<script>
import Vue from 'vue'
import {ACCESS_CustomerUser} from '@/store/mutation-types'
import {post} from '@/utils/http'
import {guid} from '@/utils/util'
import {Dialog} from 'vant';
import Notify from "vant/es/notify";
import {toDecimal} from "../utils/decimals";

export default {
  data() {
    return {
      tChecked: true,
      showPopupPassword: false,

      img: require('../assets/images/avatar.png'),
      value: '',
      showPicker: false,
      show: false,

      freeAmount: null,
      showAmount: 0,
      totalAmount: 0, // 真实选择的金额

      backUrl: '',
      loading: false,
      customerUserOptions: [],
      customerUser: {},
      noneBundleOption: [],
      bindingCardNo: '',
      bindingCardBankName: '',
      productBundleIds: [],
      sign: '',

      checked: false,
      showNotice: false,
      showNotice2: false,

      step: 1,
      password: "",
      showPasswordKeyboard: true,

      productbundleBuy: null,
      freedomBuy: null,

      showFreedomDeductible: false,
      checkFreedomDeductibleAmount: false,
      freedomDeductibleAmount: 0.4,
      result: [],
      list: [{key: "freedom", value: "餐费包充值可用零钱抵用0.4元"}],

      paymentInfo: {},
      deposit: {},

      loadingContent: "加载中...",

      deductibleData: null,

      rechargeTitle: ""
    };
  },
  watch: {
    password(val) {
      if (val.length === 6) {
        this.confirmRechargeDepositAndRecharge();
      }
    }
  },
  destroyed() {
    window.removeEventListener('popstate', this.goBack, false);
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener('popstate', this.goBack, false);
    }
    this.backUrl = this.$route.query.backUrl;

    this.setupData();
  },
  methods: {
    setupData() {
      this.step = 1;
      this.sign = guid();
      this.deposit = {};
      this.password = '';
      this.checked = false;
      if (this.customerUser && this.customerUser.businessId) {
        this.switchUser(this.customerUser);
      } else {
        this.switchUser(Vue.ls.get(ACCESS_CustomerUser));
      }

      this.setLoading("加载中...");

      post('api/customer/user/v2/search', {}).then(res => {
        this.customerUserOptions = res.data.rows
      }).catch(() => this.loading = false)
      post('api/customer/account/v2/own/read/cardNo', {}).then(res => {
        if (res.data) {
          this.bindingCardNo = res.data.bindingCardNo;
          this.bindingCardBankName = res.data.bindingCardBankName;
        }
      })
    },
    goBack() {
      let isHadBackUrl = false;
      if (this.$route.query.backUrl) {
        console.log(this.$route.query.backUrl)
        if (this.$route.query.backUrl === 'applyRechargeList') {
          isHadBackUrl = true;
          this.$router.push('/recharge/applyRechargeList');
        }
      }
      if (!isHadBackUrl) {
        this.$router.push('/customer_user_account');
      }

      window.history.pushState('forward', null, '');
      window.history.forward(1);
    },
    toggle(index) {
      // this.$refs.checkboxes[index].toggle();
      this.checkFreedomDeductibleAmount = !this.checkFreedomDeductibleAmount;
      this.change();
    },
    loadBusinessSetting() {
      post('api/business/v2/{businessId}/setting/search'.replace('{businessId}', this.customerUser.businessId), {
        size: 500
      }).then(res => {
        this.productbundleBuy = res.data.rows.find(item => item.setKey === 'PRODUCTBUNDLE_BUY'); // 是否允许购买餐包
        this.freedomBuy = res.data.rows.find(item => item.setKey === 'FREEDOM_BUY');// 是否允许购买自由消费

        if (!this.productbundleBuy) {
          this.productbundleBuy = {setValue: '0'};// 默认否，不允许购买餐包
        }
        if (!this.freedomBuy) {
          this.freedomBuy = {setValue: '1'};// 默认开启，允许购买自由消费
        }

        if (this.productbundleBuy.setValue === '1') {
          this.rechargeTitle = "请勾选缴费项目";
        }
        if (this.freedomBuy.setValue === '1') {
          this.rechargeTitle = "请输入充值金额";
        }
        if (this.productbundleBuy.setValue === '1' && this.freedomBuy.setValue === '1') {
          this.rechargeTitle = "请勾选缴费项目或输入充值金额";
        }
        if (this.freedomBuy && this.freedomBuy.setValue === '0') {
          this.loadFreedomOffset();

        } else {
          this.loading = false;

        }
      }).catch(() => {
        this.loading = false;
      });
    },
    loadFreedomOffset() {
      post('api/guangfa/v3/checkBeforeRecharge', {
        businessCustomerUserId: this.customerUser.businessCustomerUserId
      }).then(res => {
        let isZero = true;
        if (res.data) {
          this.deductibleData = res.data;
          if (this.deductibleData.totalAmount > 0) {
            isZero = false;
            this.freedomDeductibleAmount = this.deductibleData.totalAmount;
            this.showFreedomDeductible = true;
            this.list = [{key: "freedom", value: "共有零钱" + this.freedomDeductibleAmount + "元," + "可用抵用 " + 0 + " 元"}];
            this.toggle(0);
          }

        }

        if (isZero) {
          this.freedomDeductibleAmount = 0;
          this.showFreedomDeductible = false;
          this.list = [];
        }

        this.loading = false;

      }).catch(() => {
        this.loading = false;
      });
    },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
    showPopup() {
      this.show = true;
    },
    switchUser(data) {
      // 加载餐钱+零钱
      this.customerUser = data;
      this.showFreedomDeductible = false;
      this.checkFreedomDeductibleAmount = false;
      this.showAmount = 0;
      this.productBundleIds = [];
      this.freeAmount = null;
      this.show = false
      this.showNoneBuyBundle();
      this.loadBusinessSetting();
      this.loadBusinessPaymentInfo();
    },
    showNoneBuyBundle() {
      this.setLoading("加载中...");
      let url = 'api/business/v2/{businessId}/product/bundle/{businessCustomerUserId}/find/not/buy'
      url = url.replace('{businessId}', this.customerUser.businessId).replace('{businessCustomerUserId}', this.customerUser.businessCustomerUserId)
      post(url, {}).then(res => {
        this.noneBundleOption = res.data
        this.loading = false
        this.show = false
      }).catch(() => {
        this.loading = false
      })
    },
    recharge() {
      if (!this.totalAmount) {
        Notify({type: 'warning', message: '请填写您要充值的金额'});
        return;
      }

      let totalAmount = toDecimal(this.totalAmount);
      if (totalAmount <= 0) {
        Notify({type: 'warning', message: '请填写您要充值的金额'});
        return;
      }

      if (!this.freeAmount) {
        this.freeAmount = 0;// 接口不允许传null
      }
      if (this.paymentInfo.needPassword === '1') {
        if (!this.checked) {
          Notify({type: "danger", message: '请阅读和同意相关协议'});
          return;
        }
      }

      if (this.freeAmount >= 3500 && this.showGFBanner()) {
        Dialog.alert({
          title: '活动提示',
          message: '成功充值后，广发银行将于2个工作日内联系您领取50元支付宝立减券，请耐心等待。如有疑问，请联系客服咨询。'
        }).then(() => {
          this.rechargeConfirm();
        });
      } else {
        this.rechargeConfirm();
      }

    },

    rechargeConfirm() {
      this.setLoading("检查充值环境中");

      // 增加限额控制
      // 检查是否二类户
      post('api/customer/account/v2/{businessCustomerUserId}/find'.replace("{businessCustomerUserId}", this.customerUser.businessCustomerUserId), {}, true)
          .then(res => {
            this.customerAccount = res.data;

            if (this.paymentInfo.code === 'GF') {
              if (!this.customerAccount.id) {
                this.$router.push({path: '/AccountAgreement'});
                return;
              }
              // 广发
              if (this.customerAccount.accountOpenType === '3') {
                // 检查影像状态？
                // 初审成功
                if (this.customerAccount.auditStatus && this.customerAccount.auditStatus !== '03') {
                  this.loading = false;
                  Dialog.alert({title: '温馨提示', message: '您已成功提交资料进行升级，\n广发银行将于1-3个工作日内完成审核，\n审核结果以短信通知，敬请留意。'});
                  return;
                } else if (this.customerAccount.auditStatus && this.customerAccount.auditStatus === '03') {
                  Dialog.confirm({title: '温馨提示', message: '账户升级失败，请您再次升级或联系客服人员', confirmButtonText: '升级'})
                      .then(() => {
                        this.$router.push({path: '/AccountUpgradeDescription'})

                      })
                      .catch(() => {

                      });
                  return;
                }

                // 1. 获取广发余额
                post('api/guangfa/v2/queryAccountBalance', {}).then(res => {
                  let balance = toDecimal(res.data.eAccAmt)
                  // 2. 根据 充值金额+广发余额 是否 > 2000 进行比较
                  if ((toDecimal(this.showAmount) + balance) > 2000) {
                    // 3. 大于2000 提示升级，进入升级流程
                    this.loading = false;
                    Dialog.confirm({title: '温馨提示', message: '您当前的充值限额为2000,\n需升级后才能正常使用', confirmButtonText: '升级'})
                        .then(() => {
                          this.$router.push({path: '/AccountUpgradeDescription'})

                        })
                        .catch(() => {

                        });

                  } else {
                    this.createRechargeDeposit();
                  }

                }).catch(error => {
                  Notify({type: 'warning', message: error.message});
                  this.loading = false;

                });

              } else if (this.customerAccount.accountOpenType === '2') {
                if (this.customerAccount.auditStatus === '03') {
                  // 二审成功
                  this.createRechargeDeposit();
                  return;

                }
                if (this.customerAccount.auditStatus === '04') {
                  // 二审失败
                  this.$router.push({
                    path: '/AccountCreateFeedback',
                    query: {type: 'UPGRADE_FAIL', msg: this.customerAccount.auditRemark}
                  });

                } else if (this.customerAccount.auditStatus === '05' || this.customerAccount.auditStatus === '06') {
                  // 等待影相再审核
                  this.$router.push({
                    path: '/AccountCreateFeedback',
                    query: {type: 'ID_CARD_REVIEWING'}
                  });

                }
                this.loading = false;
              }
            } else {
              this.createRechargeDeposit();
            }

          }).catch(() => {
        Notify({type: 'warning', message: '充值异常，请重试'});
      });
    },
    check(no) {
      Dialog.alert({
        message: '银行系统繁忙！如您已确认成功支付，请点击确认按钮重新刷新支付结果',
        showCancelButton: true
      }).then(() => {
        // no
        this.loading = true
        let url = 'api/guangfa/v2/' + no + '/doubleCheck/log';
        post(url, {}).then(() => {
          Dialog.alert({message: '充值成功'}).then(() => {
            this.switchUser(this.customerUser);
            this.$router.push({path: '/customer_user_account'})
          });
          this.loading = false
        }).catch(error => {
          this.check(no)
          this.loading = false
        });
      });
    },
    change() {
      this.showAmount = 0;
      this.totalAmount = 0;

      this.productBundleIds.forEach(el => {
        let find = this.noneBundleOption.find(item => item.id === el)
        this.showAmount += find.totalAmount;
        this.totalAmount += find.totalAmount;
      })

      if (this.freeAmount != null && this.freeAmount < 0) {
        this.freeAmount = null;
      }

      if (this.freeAmount) {
        this.freeAmount = toDecimal(this.freeAmount).toFixed(2);
        this.showAmount += toDecimal(this.freeAmount);
        this.totalAmount += toDecimal(this.freeAmount);
      }

      // 处理显示金额
      // 计算可抵扣金额
      if (this.checkFreedomDeductibleAmount && this.showAmount > 0) {
        this.showAmount = this.showAmount - this.freedomDeductibleAmount;
        if (this.showAmount < 0) {
          this.showAmount = 0;
          this.list = [{
            key: "freedom",
            value: "共有零钱" + this.freedomDeductibleAmount + "元," + "可用抵用 " + this.totalAmount + " 元"
          }];
        } else {
          this.list = [{
            key: "freedom",
            value: "共有零钱" + this.freedomDeductibleAmount + "元," + "可用抵用 " + this.freedomDeductibleAmount + " 元"
          }];
        }
      }
    },
    loadBusinessPaymentInfo() {
      post("api/business/v2/{businessId}/business/customer/user/paymentInfoQuery".replace('{businessId}', this.customerUser.businessId))
          .then(result => {
            this.paymentInfo = result.data;
          })
          .catch(error => {
          })
    },
    // 1.下单
    createRechargeDeposit() {
      this.setLoading("生成充值订单中");

      this.deposit = {};

      let freeAmount = toDecimal(this.freeAmount);
      let actualFreedomDeductibleAmount = 0;
      let productBundleAmount = toDecimal(this.totalAmount - freeAmount);

      let productBundleList = [];
      let productBundlePayList = [];
      this.productBundleIds.forEach(value => {
        const productBundle = this.noneBundleOption.find(item => item.id === value)
        productBundleList.push(productBundle);

        productBundlePayList.push({
          productBundleId: productBundle.id,
          productBundlePriceGroupingId: productBundle.productBundlePriceGroupingId,
          singlePrice: productBundle.singlePrice,
          totalAmount: productBundle.totalAmount
        })

      });

      if (this.checkFreedomDeductibleAmount) {
        // 弹窗确认抵扣金额
        // 根据不同的支付平台进行计算
        this.deductibleData.deductibleList.forEach(deductible => {
          if ("GENERAL" === deductible.id) {
            actualFreedomDeductibleAmount += deductible.amount;
          } else {
            let find = productBundleList.find(item => item.paymentPlatformChannelId === deductible.id)
            if (find) {
              actualFreedomDeductibleAmount += toDecimal(deductible.amount);
            }
          }
        })
      }
      actualFreedomDeductibleAmount = toDecimal(actualFreedomDeductibleAmount);

      post('api/pay/deposit/recharge/create', {
        productBundlePay: productBundlePayList,
        freedomAmount: freeAmount,
        productBundleAmount: productBundleAmount,
        deductibleAmount: actualFreedomDeductibleAmount,
        businessCustomerUserId: this.customerUser.businessCustomerUserId,
        sign: this.sign,
      }).then(result => {
        this.deposit = result.data;
        if (this.deposit) {
          if (this.deposit.needPassword === '1') {
            this.loading = false;
            this.step = 2;

          } else {
            // 直接确认
            this.confirmRechargeDepositAndRecharge();
          }

        }

      }).catch(error => {
        this.loading = false;
        Notify({type: "warning", message: error.message});

      });

    },
    // 2.确认
    confirmRechargeDepositAndRecharge() {
      if (!this.deposit) {
        if (this.step === 2) {
          Notify({type: "warning", message: "充值异常，请重新充值"});
          this.step = 1;

        } else {
          Notify({type: "warning", message: "充值异常，请重新充值（-1）"});

        }
        return;
      }

      this.setLoading("充值订单确认中");

      post('api/pay/deposit/recharge/confirm', {
        depositId: this.deposit.depositId,
        password: this.password
      }).then(result => {
        if (result.data && result.data.payUrl) {
          // 跳转第三方页面
          window.location.href = result.data.payUrl;
        } else {
          // 广发钱包支付
          Dialog.alert({message: '充值成功'}).then(() => {
            this.switchUser(this.customerUser);
            this.step = 1;
            this.setupData();
          });

        }

        this.loading = false

      }).catch(error => {
        if (error.message.indexOf('交易流水号:') != -1) {
          let no = error.message.split(':')[1]
          this.check(no)
        } else {
          Dialog.alert({title: '充值失败', message: error.message}).then(() => {
            this.setupData();
          });
        }
        this.loading = false
      });

    },
    setLoading(content) {
      this.loading = true;
      this.loadingContent = content;
    },
    // 计算可抵扣的零钱金额
    calcDeductibleAmount() {

    },

    showGFBanner() {
      if (!this.customerUser || !this.customerUser.businessCustomerUser) {
        return false;
      }
      const businessId = this.customerUser.businessCustomerUser.businessId;
      // 深村小学、石湾一小、三龙湾小学、紫南小学、铁军小学、黎涌小学、冼可澄纪念小学、保利天珀幼儿园、惠雅幼儿园、一天测试学校
      // 一天膳事测试学校
      if (
          businessId === '017c0b4f337b06b72c96c1f37bfa026a' ||
          businessId === '017c124eaa6c14f42c96c1f37c0e1ec6' ||
          // businessId === '017b9b99ec221ad92c96c1f37b973694' ||
          businessId === '017c1096128a03a82c96c1f37c0e1ec6' ||
          businessId === '017be2d1e83a68ca2c96c1f37bdfc369' ||
          businessId === '017ba96e9316061c2c96c1f37ba23995' ||
          businessId === '017c7275f66e01bf2c96c1f37c70498f' ||
          // businessId === '018266eaad4c26082c96c1f382573a7f' ||
          // businessId === '01821afe2ad029852c96c1f3820a96e9' ||
          businessId === '017b97396de9000a2c96c1f37b973694') {
        return true;
      }
      return false;
    },
    onClickBanner() {
      if (!this.customerUser || !this.customerUser.businessCustomerUser) {
        return;
      }
      this.$router.push({name: 'activityGuangfa3500'});
    },

  }
};
</script>
